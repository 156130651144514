import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiClientService } from '../api-client.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list-vipfound',
  templateUrl: './list-vipfound.component.html',
  styleUrls: ['./list-vipfound.component.scss']
})
export class ListVipfoundComponent implements OnInit {
  currentItem: any;
  items = [];
  id: string;
  iconOnline: string;
  nameToSearch: string;
  @ViewChild('closeEdit', { static: false }) closeEdit: any;
  @ViewChild('confirmationPopup', { static: false }) confirmationPopup: any;
  tempItems = [];
  subscription: Subscription;
  itemFoundForm: FormGroup;
  actionToDoAfterConfirmation;
  confirmationMessage: string;
  constructor(
    private apiClientService: ApiClientService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router) {
    this.subscription = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.id = this.route.snapshot.paramMap.get('state');
        let url = this.router.url;
        if (this.id != null && url.includes("vipfound")) {
          this.getItems();
        }
      }
    });

  }

  ngOnInit() {
    let item ={"item":[]};
    this.updateForm(item);
    this.getItems();
    this.getAllItems();
  }


  allEltForResearch = [];
  
  getAllItems() {
    let MAX_INT = 2147483647;
    if (this.id === "enable") {
      this.apiClientService.getVipItemFound(this.getAllUsersObserver,this.page,MAX_INT);
    } else if (this.id === "disable") {
      this.apiClientService.getVipItemFoundDisable(this.getAllUsersObserver,this.page,MAX_INT);
    } else if (this.id === "delete") {
      this.apiClientService.getVipItemFoundDelete(this.getAllUsersObserver,this.page,MAX_INT);
    }
  }
  getAllUsersObserver = {
    next: (resp) => {
      this.allEltForResearch = resp;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { }
  };



  getItems() {
    this.items = [];
    if (this.id === "enable") {
      this.apiClientService.getVipItemFound(this.getItemsObserver,this.page,30);
    } else if (this.id === "disable") {
      this.apiClientService.getVipItemFoundDisable(this.getItemsObserver,this.page,30);
    } else if (this.id === "delete") {
      this.apiClientService.getVipItemFoundDelete(this.getItemsObserver,this.page,30);
    }
    this.removeLeftMenu();

  }

  getItemsObserver = {
    next: (resp) => {
      this.items=[];
      for (let item of resp) {
        if (item.item != null) {
          this.items.push(item);
        }
      }
      this.tempItems = this.items;

    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };

  updateForm(it) {
    this.itemFoundForm = this.formBuilder.group({
      type: it.item.type,
      id: it.id,
      country: it.item.country,
      town: it.item.town,
      description: it.item.description,
      recorderPhone: it.item.recorderPhone,
      nom: it.item.nom,
      isonline: it.enable,
      vipService: it.item.vipService,
      colabo: it.colaboName,
      colaboPhone: it.colaboPhone,
      createdDate: it.createdDate
    });
  }

  edit(item) {
    this.currentItem = item;
    this.updateForm(item);

  }

  search() {
    this.items = [];
    console.log(this.nameToSearch);
    if (!(this.nameToSearch === "")) {
      for (let item of this.allEltForResearch) {
        if (item.item.nom.toLocaleLowerCase().indexOf(this.nameToSearch.toLocaleLowerCase()) !== -1) {
          this.items.push(item);
        }
      }
    } else {
      this.items = this.tempItems;
    }
  }

  removeLeftMenu() {
    let body: HTMLElement = document.getElementById("bodyHome");
    body.className = "sb-nav-fixed";

  }

  disableObserver = {
    next: (resp) => {
      document.getElementById("buttonblock").style.display = "block";
      document.getElementById("sorelusLoading").style.display = "none";
      this.updateForm({"item":[]});
      this.getItems();
      this.closeEdit.nativeElement.click();

    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };

  setTovalid(message: string) {
    this.actionToDoAfterConfirmation = () => {
      document.getElementById("buttonblock").style.display = "none";
      document.getElementById("sorelusLoading").style.display = "block";
      this.apiClientService.vipItemFundSetDisable(this.currentItem.id, this.disableObserver);
    };
    this.confirmationMessage = message;
    this.confirmationPopup.nativeElement.click();
  }

  setToDelete(item, message: string) {
    if (item != null) {
      console.log(item);
      this.currentItem = item;
    }
    this.actionToDoAfterConfirmation = () => {
      document.getElementById("buttonblock").style.display = "none";
      document.getElementById("sorelusLoading").style.display = "block";
      this.apiClientService.vipItemFundSetDelete(this.currentItem.id, this.disableObserver);
    };
    this.confirmationMessage = message;
    this.confirmationPopup.nativeElement.click();
  }

  runAfterConfirmation() {
    this.actionToDoAfterConfirmation();
  }

  page : number = 0;
  prevElt(){
    this.page = this.page - 1;
    if(this.page < 0){
      this.page=0;
    }
    this.getItems();
  }

  nextElt(){
    this.page = this.page + 1;
    this.getItems();
  }
}
