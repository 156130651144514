import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, IterableDiffers } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgForm, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit, AfterViewInit, OnDestroy {

  subscription: Subscription;
  currentItem: any;
  items = [];
  id: string;
  iconOnline: string;
  nameToSearch: string;
  @ViewChild('closeEdit', { static: false }) closeEdit: any;
  @ViewChild('confirmationPopup', { static: false }) confirmationPopup: any;
  tempItems = [];
  actif: boolean;
  roles = [
    "admin", "root", "LOGIN"
  ]
  rolesId = [false, false, false];
  item = {
    "roles": ""
  };
  itemFoundForm: FormGroup;
  actionToDoAfterConfirmation;
  confirmationMessage: string;
  page : number = 0;
  //confirmationhandler : number;

  constructor(private apiClientService: ApiClientService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder) {
    this.subscription = router.events.subscribe((val) => {

      if (val instanceof NavigationEnd) {
        this.id = this.route.snapshot.paramMap.get('state');
        let url = this.router.url;
        if (this.id != null && url.includes("user")) {
          this.getUsers();
          this.getAllItems();
        }
      }

    });
  }

  ngOnInit() {

    this.updateForm([]);
  }
  ngAfterViewInit() {


  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  removeLeftMenu() {
    let body: HTMLElement = document.getElementById("bodyHome");
    body.className = "sb-nav-fixed";

  }
  allEltForResearch = [];
  
  getAllItems() {
    let MAX_INT = 2147483647;
    if (this.id === "disable") {
      this.apiClientService.getUserDisable(this.getAllUsersObserver,this.page,MAX_INT);
    } else if (this.id === "enable") {
      this.apiClientService.getUserEnable(this.getAllUsersObserver,this.page,MAX_INT);
    }
  }
  getAllUsersObserver = {
    next: (resp) => {
      this.allEltForResearch = resp;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { }
  };

  getUsers() {
    this.items = [];
    if (this.id === "disable") {
      this.apiClientService.getUserDisable(this.getItemsDisableObserver,this.page,30);
    } else if (this.id === "enable") {
      this.apiClientService.getUserEnable(this.saveUserObserver,this.page,30);
    }
    this.removeLeftMenu();
  }

  saveUserObserver = {
    next: (resp) => {
      this.items = resp;
      this.tempItems = this.items;
      this.closeEdit.nativeElement.click();

    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };

  getItemsDisableObserver = {
    next: (resp) => {
      this.items = resp;
      this.tempItems = this.items;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };

  saveLostItemObserver = {
    next: (response) => {
      this.closeEdit.nativeElement.click();
      //this.getItems();

    },
    error: (error) => {
      console.log(error);
    }
  }


  edit(item) {
    this.currentItem = item;
    this.updateForm(item);
  }



  setTovalid(item, bool: boolean, message: string) {
    this.actionToDoAfterConfirmation = () => {
      this.currentItem = item;
      this.currentItem.enable = bool;
      this.apiClientService.saveLostItem(this.currentItem, this.saveLostItemObserver);
    };
    this.confirmationMessage = message;
    this.confirmationPopup.nativeElement.click();
  }

  deleteItem(item, message: string) {
    this.actionToDoAfterConfirmation = () => {
      this.currentItem = item;
      this.apiClientService.deleteLostItem(this.currentItem.id, this.saveLostItemObserver);
    };
    this.confirmationMessage = message;
    this.confirmationPopup.nativeElement.click();
  }


  runAfterConfirmation() {
    this.actionToDoAfterConfirmation();
  }


  saveInfo(message) {
    this.actionToDoAfterConfirmation = () => {
      const formValue = this.itemFoundForm.value;
      this.currentItem.town = formValue['town'];
      this.currentItem.username = formValue['login'];
      this.currentItem.name = formValue['name'];
      this.currentItem.phone = formValue['number'];
      let roles = [];
      for (let i in this.roles) {
        if (this.rolesId[i] == true) {
          roles.push(this.roles[i]);
        }
      }
      this.currentItem.roles =roles
      this.apiClientService.updateUser(this.currentItem, this.saveUserObserver);
     
    }

    this.confirmationMessage = message;
    this.confirmationPopup.nativeElement.click();
  }

  getR(i: number): boolean {
    if (this.item.roles) {
      return this.item.roles.includes(this.roles[i]);
    }

    return false;
  }

  updateForm(item) {
    this.item = item;
    this.itemFoundForm = this.formBuilder.group({
      town: item.town,
      name: item.name,
      role: item.role,
      createdDate: item.createdDate,
      login: item.username,
      number: item.phone,
    });
    this.actif = !(item.roles == null || item.roles[0] == null || item.roles[0] === "LOGIN");
    if(this.currentItem){
      for(let i in this.roles){
        this.rolesId[i] =  this.currentItem.roles.includes(this.roles[i]);
      }
    }
    
  }

  search() {
    this.items = [];
    if (!(this.nameToSearch === "")) {
      for (let item of this.allEltForResearch) {
        if (item.name.toLocaleLowerCase().indexOf(this.nameToSearch.toLocaleLowerCase()) !== -1) {
          this.items.push(item);
        }
      }
    } else {
      this.items = this.tempItems;
    }
  }

  getRole(item) {
    return "["+item.roles+"]";
  }

  FieldsChange(values: any, i: number) {
    this.rolesId[i] = values.currentTarget.checked;
  }

  prevElt(){
    this.page = this.page - 1;
    if(this.page < 0){
      this.page=0;
    }
    this.getUsers();
  }

  nextElt(){
    this.page = this.page + 1;
    this.getUsers();
  }
}
