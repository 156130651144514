import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-menutop',
  templateUrl: './menutop.component.html',
  styleUrls: ['./menutop.component.scss']
})
export class MenutopComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }

  showLeftMenu(){
    let body : HTMLElement = document.getElementById("bodyHome");
    
    if(body.className==="sb-nav-fixed"){
      body.className = "sb-nav-fixed  sb-sidenav-toggled";
    }else{
      body.className = "sb-nav-fixed";
    }
     

  }

  getName():string{
    let meJson = localStorage.getItem('me');
    const user : any = JSON.parse(meJson);
    return user.name;
  }

  logout(){
    localStorage.removeItem("help_token");
    this.router.navigate(['/login']);
  }

}
