import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ApiClientService } from '../api-client.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GadgetCreateFormComponent } from '../gadget-create-form/gadget-create-form.component';

@Component({
  selector: 'app-gadget-list-enable',
  templateUrl: './gadget-list-enable.component.html',
  styleUrls: ['./gadget-list-enable.component.scss']
})
export class GadgetListEnableComponent implements OnInit {
  subscription: Subscription;
  currentItem: any;
  items = [];
  id: string;
  iconOnline: string; 
  nameToSearch: string;
  @ViewChild('closeEdit', { static: false }) closeEdit: any;
  @ViewChild('confirmationPopup', { static: false }) confirmationPopup: any;
  @ViewChild('editGadget', { static: false }) editGadget: any;
  @ViewChild('closeGadgetEdit', { static: false }) closeGadgetEdit: any;
  tempItems = [];

  itemFoundForm: FormGroup;
  actionToDoAfterConfirmation;
  confirmationMessage: string;
  constructor(private apiClientService: ApiClientService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder) {
      this.subscription = router.events.subscribe((val) => {

        if (val instanceof NavigationEnd) {
          this.id = this.route.snapshot.paramMap.get('state');
          let url = this.router.url;
          if (this.id != null && url.includes("gadgets")) {
            this.getGadgets();
          }
        }
   
      });
     }

  ngOnInit() {
  }


  getGadgets() {
    this.items = [];
    if (this.id === "dispo") {
      this.apiClientService.getAllGadget(this.getGadgetsObserver);
    }
    //  else if (this.id === "disable") {
    //   this.apiClientService.getAllGadgetDisable(this.getGadgetsObserver);
    // }
    this.removeLeftMenu();
  }

  getGadgetsObserver = {
    next: (resp) => {
      this.items = resp;
      this.tempItems = this.items;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };

  removeLeftMenu() {
    let body: HTMLElement = document.getElementById("bodyHome");
    body.className = "sb-nav-fixed";

  }

  search() {
    this.items = [];
    console.log(this.nameToSearch);
    if(!(this.nameToSearch==="")){
      for (let item of this.tempItems) {
        if (item.label.toLocaleLowerCase().indexOf(this.nameToSearch.toLocaleLowerCase()) !== -1) {
          this.items.push(item);
        }
      }
    }else{
      this.items = this.tempItems;
    }
  }

  edit(item){
    GadgetCreateFormComponent.fromListItem = item;
    GadgetCreateFormComponent.fromList = true;
    GadgetCreateFormComponent.fromListObserver =this.updateObserver; 
    (<HTMLInputElement>document.getElementById("label")).value =item.label; 
    (<HTMLInputElement>document.getElementById("price")).value =item.price; 
    (<HTMLInputElement>document.getElementById("constructor")).value =item.constructor; 
    (<HTMLInputElement>document.getElementById("phone")).value =item.constructorPhone; 
    (<HTMLInputElement>document.getElementById("quantity")).value =item.qty; 
    (<HTMLInputElement>document.getElementById("detail")).value =item.detail; 
    var output = <HTMLImageElement>document.getElementById('output');
    if(item.imageUrl!=null && item.imageUrl!=""){
      output.src =this.apiClientService.serverImageUrl+item.imageUrl;
      output.onload = function () {
        URL.revokeObjectURL(output.src)
      }
    }
    this.editGadget.nativeElement.click();
  }

  updateObserver= {
    next: (resp) => {
      this.closeGadgetEdit.nativeElement.click();
      this.getGadgets();
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };
}
