import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, IterableDiffers } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgForm, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-list-items-lost',
  templateUrl: './list-items-lost.component.html',
  styleUrls: ['./list-items-lost.component.scss']
})
export class ListItemsLostComponent implements OnInit, AfterViewInit, OnDestroy {

  subscription: Subscription;
  currentItem: any;
  items = [];
  id: string;
  iconOnline: string; 
  nameToSearch: string;
  @ViewChild('closeEdit', { static: false }) closeEdit: any;
  @ViewChild('confirmationPopup', { static: false }) confirmationPopup: any;
  tempItems = [];

  itemFoundForm: FormGroup;
  actionToDoAfterConfirmation;
  confirmationMessage: string;
  //confirmationhandler : number;

  constructor(private apiClientService: ApiClientService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder) {
    this.subscription = router.events.subscribe((val) => {

      if (val instanceof NavigationEnd) {
        this.id = this.route.snapshot.paramMap.get('state');
        let url = this.router.url;
        if (this.id != null && url.includes("items_losts")) {
          this.getItems();
          this.getAllItems();
        }
      }

    });
  }

  ngOnInit() {

    this.updateForm([]);
  }
  ngAfterViewInit() {


  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  removeLeftMenu() {
    let body: HTMLElement = document.getElementById("bodyHome");
    body.className = "sb-nav-fixed";

  }

  allEltForResearch = [];
  
  getAllItems() {
    let MAX_INT = 2147483647;
    if (this.id === "disable") {
      this.apiClientService.getDisableItemLost(this.getAllUsersObserver,this.page,MAX_INT);
    } else if (this.id === "enable") {
      this.apiClientService.getActifItemLost(this.getAllUsersObserver,this.page,MAX_INT);
    }
  }
  getAllUsersObserver = {
    next: (resp) => {
      this.allEltForResearch = resp;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { }
  };

  getItems() {
    this.items = [];
    if (this.id === "disable") {
      this.apiClientService.getDisableItemLost(this.getItemsDisableObserver,this.page,30);
    } else if (this.id === "enable") {
      this.apiClientService.getActifItemLost(this.getItemsObserver,this.page,30);
    }
    this.removeLeftMenu();
  }

  getItemsObserver = {
    next: (resp) => {
      this.items = resp;
      this.tempItems = this.items;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };

  getItemsDisableObserver = {
    next: (resp) => {
      this.items = resp;
      this.tempItems = this.items;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };

  saveLostItemObserver = {
    next: (response) => {
      this.closeEdit.nativeElement.click();
      this.getItems();
      
    },
    error: (error) => {
      console.log(error);
    }
  }


  edit(item) {
    this.currentItem = item;
    this.updateForm(item);
  }



  setTovalid(item, bool: boolean, message: string) {
    this.actionToDoAfterConfirmation = () => {
      this.currentItem = item;
      this.currentItem.enable = bool;
      this.apiClientService.saveLostItem(this.currentItem, this.saveLostItemObserver);
    };
    this.confirmationMessage = message;
    this.confirmationPopup.nativeElement.click();
  }

  deleteItem(item, message: string) {
    this.actionToDoAfterConfirmation = () => {
      this.currentItem = item;
      this.apiClientService.deleteLostItem(this.currentItem.id, this.saveLostItemObserver);
    };
    this.confirmationMessage = message;
    this.confirmationPopup.nativeElement.click();
  }


  runAfterConfirmation() {
    this.actionToDoAfterConfirmation();
  }


  saveInfo(message) {
    this.actionToDoAfterConfirmation = () => {
      const formValue = this.itemFoundForm.value;
      this.currentItem.type = formValue['type'];
      this.currentItem.town = formValue['town'];
      this.currentItem.description = formValue['description'];
      this.currentItem.nom = formValue['nom'];
      this.currentItem.id = formValue['id'];
      this.currentItem.enable = formValue['isonline'];
      this.currentItem.vipService = formValue['vipService'];
      this.currentItem.recorderPhone = formValue['recorderPhone'];
      this.apiClientService.saveLostItem(this.currentItem, this.saveLostItemObserver);
    }
    this.confirmationMessage = message;
    this.confirmationPopup.nativeElement.click();
  }

  updateForm(item) {
    this.itemFoundForm = this.formBuilder.group({
      type: item.type,
      country: item.country,
      town: item.town,
      description: item.description,
      recorderPhone: item.recorderPhone,
      nom: item.nom,
      isonline: item.enable,
      id: item.id,
      vipService:item.vipService,
      createdDate: item.createdDate
    });
  }

  search() {
    this.items = [];
    console.log(this.nameToSearch);
    if(!(this.nameToSearch==="")){
      for (let item of this.allEltForResearch) {
        if (item.nom.toLocaleLowerCase().indexOf(this.nameToSearch.toLocaleLowerCase()) !== -1) {
          this.items.push(item);
        }
      }
    }else{
      this.items = this.tempItems;
    }
  }

  page : number = 0;
  prevElt(){
    this.page = this.page - 1;
    if(this.page < 0){
      this.page=0;
    }
    this.getItems();
  }

  nextElt(){
    this.page = this.page + 1;
    this.getItems();
  }
}
