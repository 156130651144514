import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menuleft',
  templateUrl: './menuleft.component.html',
  styleUrls: ['./menuleft.component.scss']
})
export class MenuleftComponent implements OnInit {

  user: any;
  constructor() { 
    let meJson = localStorage.getItem('me');
   this.user = JSON.parse(meJson);
  }

  ngOnInit() {
  }

  iCanSee(menu : string) : boolean{
    return this.user.roles.includes(menu);
  }

}
