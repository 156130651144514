import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../api-client.service';

@Component({
  selector: 'app-gadget-command-list',
  templateUrl: './gadget-command-list.component.html',
  styleUrls: ['./gadget-command-list.component.scss']
})
export class GadgetCommandListComponent implements OnInit {

  items =[];
  currentItem: any;
  id: string;
  iconOnline: string;
  nameToSearch: string;
  tempItems = [];

  constructor(private apiClientService: ApiClientService) { }

  ngOnInit() {
    this.getCommands();
  }

  getCommands(){
    this.items = [];
    this.apiClientService.getAllCommands(this.getUsersObserver);
    this.removeLeftMenu();
  }

  getUsersObserver = {
    next: (resp) => {
      this.items = resp;
      this.tempItems = this.items;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };

  removeLeftMenu() {
    let body: HTMLElement = document.getElementById("bodyHome");
    body.className = "sb-nav-fixed";

  }

  search() {
    this.items = [];
    console.log(this.nameToSearch);
    if(!(this.nameToSearch==="")){
      for (let item of this.tempItems) {
        if (item.gadget.label.toLocaleLowerCase().indexOf(this.nameToSearch.toLocaleLowerCase()) !== -1) {
          this.items.push(item);
        }
      }
    }else{
      this.items = this.tempItems;
    }
  }
}
