import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiClientService } from '../api-client.service';

@Component({
  selector: 'app-list-person-lost',
  templateUrl: './list-person-lost.component.html',
  styleUrls: ['./list-person-lost.component.scss']
})
export class ListPersonLostComponent implements OnInit {

  currentItem: any;
  items = [];
  id: string;
  iconOnline: string;
  nameToSearch: string;
  @ViewChild('closeEdit', { static: false }) closeEdit: any;
  @ViewChild('confirmationPopup', { static: false }) confirmationPopup: any;
  tempItems = [];

  itemFoundForm: FormGroup;
  actionToDoAfterConfirmation;
  confirmationMessage: string;
  constructor(private apiClientService: ApiClientService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.updateForm([]);
    this.getLostHumains();
    this. getAllItems();
  }

  allEltForResearch = [];
  
  getAllItems() {
    let MAX_INT = 2147483647;
    this.apiClientService.getLostHumains(this.getAllUsersObserver,this.page,MAX_INT);
  }
  getAllUsersObserver = {
    next: (resp) => {
      this.allEltForResearch = resp;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { }
  };

  getLostHumains() {
    this.items = [];
    this.apiClientService.getLostHumains(this.getLostHumainsObserver,this.page,30);
    this.removeLeftMenu();
  }

  getLostHumainsObserver = {
    next: (resp) => {
      this.items = resp;
      this.tempItems = this.items;

    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };

  updateForm(item) {
    this.itemFoundForm = this.formBuilder.group({
      type: this.getStatut(item),
      id: item.id,
      country: item.country,
      town: item.town,
      description: item.description,
      recorderPhone: item.recorderPhone,
      nom: item.nom,
      isonline: item.enable,
      vipService: item.vipService,
      colabo: item.colaboName,
      colaboPhone: item.colaboPhone,
      createdDate: item.createdDate
    });

  }

  edit(item) {
    this.currentItem = item;
    this.updateForm(item);

  }

  search() {
    this.items = [];
    console.log(this.nameToSearch);
    if (!(this.nameToSearch === "")) {
      for (let item of this.allEltForResearch) {
        if (item.nom.toLocaleLowerCase().indexOf(this.nameToSearch.toLocaleLowerCase()) !== -1) {
          this.items.push(item);
        }
      }
    } else {
      this.items = this.tempItems;
    }
  }

  removeLeftMenu() {
    let body: HTMLElement = document.getElementById("bodyHome");
    body.className = "sb-nav-fixed";

  }

  getStatut(item) {
    if (!item.lostOrFound) {
      return 'Perdu'
    } else {
      return 'Trouvé'
    }
  }

  showImage(item) {
    var modalImg = <HTMLImageElement>document.getElementById("img01");
    if(item.imageUrl!=null && item.imageUrl!=""){
      modalImg.src = this.apiClientService.serverImageUrl+item.imageUrl;
    }else{
      modalImg.src ="/assets/img/error-404-monochrome.svg"
    }
    
  }

  page : number = 0;
  prevElt(){
    this.page = this.page - 1;
    if(this.page < 0){
      this.page=0;
    }
    this.getLostHumains();
  }

  nextElt(){
    this.page = this.page + 1;
    this.getLostHumains();
  }
}