import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { ApiClientService } from '../api-client.service';

@Component({
  selector: 'app-gadget-create',
  templateUrl: './gadget-create.component.html',
  styleUrls: ['./gadget-create.component.scss']
})
export class GadgetCreateComponent implements OnInit {
  
  
  ngOnInit(): void {
    
  }

}
