import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiClientService } from '../api-client.service';

@Component({
  selector: 'app-list-customers',
  templateUrl: './list-customers.component.html',
  styleUrls: ['./list-customers.component.scss']
})
export class ListCustomersComponent implements OnInit {
  currentItem: any;
  items = [];
  id: string;
  iconOnline: string;
  nameToSearch: string;
  @ViewChild('closeEdit', { static: false }) closeEdit: any;
  @ViewChild('confirmationPopup', { static: false }) confirmationPopup: any;
  tempItems = [];
  page : number = 0;
  itemFoundForm: FormGroup;
  actionToDoAfterConfirmation;
  confirmationMessage: string;
  subscription: Subscription;
  constructor(private apiClientService: ApiClientService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router) { 
      this.subscription = router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.getItem();
        }
      });
  }

  ngOnInit() {
    this.getItem();
  }

  getItem(){
    this.id = this.route.snapshot.paramMap.get('state');
    if(this.id=="objets"){
      this.getPersonInterest();
    }else{
    this.getUsers()
    }
    this.updateForm([]);
    this.getAllUsers();
  }


  allEltForResearch = [];
  
  getPersonInterest(){
    this.apiClientService.getAllCustomerInterest(this.getUsersObserver,1,1);
    this.removeLeftMenu();
  }

  getAllUsers() {
    let MAX_INT = 2147483647;
    this.apiClientService.getAllCustomer(this.getAllUsersObserver,this.page,MAX_INT);
    this.removeLeftMenu();
  }
  getAllUsersObserver = {
    next: (resp) => {
      this.allEltForResearch = resp;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { }
  };

  getUsers() {
    this.items = [];
    this.apiClientService.getAllCustomer(this.getUsersObserver,this.page,30);
    this.removeLeftMenu();
  }

  getUsersObserver = {
    next: (resp) => {
      this.items = resp;
      this.tempItems = this.items;
    },
    error: err => {
      console.log(err)
    },
    complete: (resp) => { console.log("complete...") }
  };

  removeLeftMenu() {
    let body: HTMLElement = document.getElementById("bodyHome");
    body.className = "sb-nav-fixed";

  }

  updateForm(item) {
    this.itemFoundForm = this.formBuilder.group({
      country: item.country,
      town: item.town,
      phone: item.phone,
      name: item.name,
      code: this.reformateCode(item.code),
      id: item.id,
      createdDate: item.createdDate
    });
  }
  edit(item) {
    this.currentItem = item;
    this.updateForm(item);
  }
  search() {
    document.getElementById("searchCustomerButton").style.display = "none";
    document.getElementById("searchCustomerLoader").style.display = "block";
    this.items = [];
    if(!(this.nameToSearch==="")){
      for (let item of this.allEltForResearch) {
        if (item.name.toLocaleLowerCase().indexOf(this.nameToSearch.toLocaleLowerCase()) !== -1) {
          this.items.push(item);
        }
      }
    }else{
      this.items = this.tempItems;
    }
    document.getElementById("searchCustomerButton").style.display = "block";
    document.getElementById("searchCustomerLoader").style.display = "none";
  }

  reformateCode(code) : string{
    if(code!=null && code!="")
    code = code.split(".")[0];
    return code;
  }

  prevElt(){
    this.page = this.page - 1;
    if(this.page < 0){
      this.page=0;
    }
    this.getUsers();
  }

  nextElt(){
    this.page = this.page + 1;
    this.getUsers();
  }
}
