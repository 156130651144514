import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { Routes,RouterModule,Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  
  constructor() { }

  className1 : string = "sb-nav-fixed sb-sidenav-toggled"
  clssName2 ="sb-nav-fixed";


  ngOnInit() {
  }

}
