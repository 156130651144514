import { Component, OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiClientService } from '../api-client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit,AfterViewInit {
  @ViewChild('openRegistrationConfimation', {static: false}) openModal;
  @ViewChild('closeRegistrationConfimation', {static: false}) closeModal;
  constructor(private apiClientService :ApiClientService,public router: Router) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  createUser(form: NgForm){
    let error = document.getElementById("RegisterErrorPassword");
    error.style.display="none";
    if(form.value['pass1']===form.value['pass2']){
      let user ={
        "quarter": this.cleanSpecialChar(form.value['ktier']),
        "password":this.cleanSpecialChar(form.value['pass1']),
        "username":this.cleanSpecialChar(form.value['login']),
        "name":this.cleanSpecialChar(form.value['name']),
        "phone":this.cleanSpecialChar(form.value['phone']),
        "country":this.cleanSpecialChar("Cameroun"),
        "town":this.cleanSpecialChar(form.value['town'])
      };
      this.apiClientService.createUser(user,this.createUserObserver);
    }else{
      error.style.display="block";
    }


  } 
  createUserObserver = {
    next: (resp) => {
      this.openModal.nativeElement.click();
    },
    error: err => {
      let error = document.getElementById("RegisterErrorBack");
      error.style.display="block";
    },
    complete: (resp) => {console.log("complete...")}
  };


  goToLogin(){
    this.closeModal.nativeElement.click();
    this.router.navigate(['/login']);
  }

  cleanSpecialChar(txt:string) : string {
    let toConvert= txt+"";
    return toConvert+"".replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
  }

}
