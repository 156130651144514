import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  user: any;
  constructor() { 
    let meJson = localStorage.getItem('me');
   this.user = JSON.parse(meJson);
  }
 
  //name = this.user.name;

  ngOnInit() {
  }

}
