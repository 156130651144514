import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { Routes, RouterModule, Router } from '@angular/router';
import { ApiClientService } from './api-client.service';
import { AuthGuardService } from './security/auth-guard.service';
import { AuthService } from './security/auth.service';
import { JwtModule, JwtModuleOptions, JwtHelperService } from '@auth0/angular-jwt';
import { MenutopComponent } from './menutop/menutop.component';
import { MenuleftComponent } from './menuleft/menuleft.component';
import { FooterComponent } from './footer/footer.component';
import { ListItemsFoundsComponent } from './list-items-founds/list-items-founds.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthTokenInterceptorService } from './security/auth-token-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ListItemsLostComponent } from './list-items-lost/list-items-lost.component';
import { ListCustomersComponent } from './list-customers/list-customers.component';
import { ListVipfoundComponent } from './list-vipfound/list-vipfound.component';
import { GadgetCreateComponent } from './gadget-create/gadget-create.component';
import { GadgetListEnableComponent } from './gadget-list-enable/gadget-list-enable.component';
import { GadgetCreateFormComponent } from './gadget-create-form/gadget-create-form.component';
import { GadgetCommandListComponent } from './gadget-command-list/gadget-command-list.component';
import { ListPersonLostComponent } from './list-person-lost/list-person-lost.component';
import { ListUserComponent } from './list-user/list-user.component';

const appRoutes: Routes = [
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: 'items_founds/:state', component: ListItemsFoundsComponent, canActivate: [AuthGuardService] },
  { path: 'items_losts/:state', component: ListItemsLostComponent, canActivate: [AuthGuardService] },
  { path: 'customers/:state', component: ListCustomersComponent, canActivate: [AuthGuardService] },
  { path: 'vipfound/:state', component: ListVipfoundComponent, canActivate: [AuthGuardService] },
  { path: 'gadget/create', component: GadgetCreateComponent, canActivate: [AuthGuardService] },
  { path: 'gadget/sale', component: GadgetCommandListComponent, canActivate: [AuthGuardService] },
  { path: 'gadgets/:state', component: GadgetListEnableComponent, canActivate: [AuthGuardService] },
  { path: 'humain/lost', component: ListPersonLostComponent, canActivate: [AuthGuardService] },
  { path: 'user/:state', component: ListUserComponent, canActivate: [AuthGuardService] },
  { path: '', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];




const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter: function tokenGetter() {
      let token = localStorage.getItem('help_token');
      return token;
    },
    whitelistedDomains: ['localhost:8080', 'helpmoi.com:8080']
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    MenutopComponent,
    MenuleftComponent,
    FooterComponent,
    ListItemsFoundsComponent,
    DashboardComponent,
    ListItemsLostComponent,
    ListCustomersComponent,
    ListVipfoundComponent,
    GadgetCreateComponent,
    GadgetListEnableComponent,
    GadgetCreateFormComponent,
    GadgetCommandListComponent,
    ListPersonLostComponent,
    ListUserComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    JwtModule.forRoot(JWT_Module_Options),

  ],
  providers: [
    ApiClientService,
    JwtHelperService,
    AuthGuardService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
