import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  login: string = "";
  password: string = "";
  static userData : any;

  constructor(public router: Router, private apiClientService: ApiClientService) {
  }

  ngOnInit() {

  }

  getToken(form: NgForm) {
    this.login = form.value['login'];
    this.password = form.value['password'];
    document.getElementById("sorelusLoading").style.display = "block";
    document.getElementById("sorelusSubmitButton").style.display = "none";
    document.getElementById("messageError").style.display = "none";
    document.getElementById("messageWait").style.display = "none";

    this.apiClientService.getToken(this.login, this.password, this.getTokenObserver);
  }
 
  getTokenObserver = {
    next: (resp) => {
      if (resp.user.roles == null || resp.user.roles[0] == null || resp.user.roles[0] === "LOGIN") {
        document.getElementById("messageError").style.display = "none";
        document.getElementById("messageWait").style.display = "block";
        this.hiddenWaitMessage();
      } else {
        localStorage.setItem('help_token', resp.token);
        localStorage.setItem('me', JSON.stringify(resp.user));
        this.router.navigate(['/']);
      }
    },
    error: err => {
      this.hiddenWaitMessage();
      document.getElementById("messageError").style.display = "block";
      document.getElementById("messageWait").style.display = "none";
    },
    complete: (resp) => {console.log("complete...")}
  };

  hiddenWaitMessage(){
    document.getElementById("sorelusLoading").style.display = "none";
    document.getElementById("sorelusSubmitButton").style.display = "block";
  }
}
