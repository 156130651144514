import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  serverUrl : string = "https://shanafrika.com/tomcat/helpmoi/"

  public serverImageUrlBase : string = "https://shanafrika.com/tomcat/filesManager/"

  public serverImageUrl : string = this.serverImageUrlBase+"files/"
 
  constructor(private httpClient: HttpClient, public router: Router) { }


  getToken(login : string,password : string, observer : any) :any {
    let url = "login?login="+login+"&"+"password="+password;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  createUser( data : any, observer : any) :any {
    let url = "register";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);
  }

  getActifItemFound(observer : any,page : any, count : any) :any {
    let url = "foundItem/admin/AllEnable?page="+page+"&count="+count;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  getDisableItemFound(observer : any,page : any, count : any) :any {
    let url = "foundItem/admin/allDisable?page="+page+"&count="+count;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  saveFoundItem( data : any, observer : any) :any {
    let url = "foundItem/admin/update";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);
  }

  deleteFoundItem( id : any, observer : any) :any {
    let url = "foundItem/admin/delete?id="+id;
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),null)
      .subscribe(observer);
  }


  getActifItemLost(observer : any,page : any, count : any) :any {
    let url = "lostItem/admin/AllEnable?page="+page+"&count="+count;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  getDisableItemLost(observer : any,page : any, count : any) :any {
    let url = "lostItem/admin/allDisable?page="+page+"&count="+count;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  saveLostItem( data : any, observer : any) :any {
    let url = "lostItem/admin/update";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);
  }

  deleteLostItem( id : any, observer : any) :any {
    let url = "lostItem/admin/delete?id="+id;
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),null)
      .subscribe(observer);
  }

  getAllCustomer(observer : any,page : any, count : any) :any {
    let url = "customer/admin/all?page="+page+"&count="+count;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  getAllCustomerInterest(observer : any,page : any, count : any) :any {
    let url = "customer/admin/interest/all?page="+page+"&count="+count;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  getVipItemFound(observer : any,page : any, count : any) :any {
    let url = "foundItem/admin/vip/all?page="+page+"&count="+count;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }
  getVipItemFoundDisable(observer : any,page : any, count : any) :any {
    let url = "foundItem/admin/vip/allDisable?page="+page+"&count="+count;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }
  getVipItemFoundDelete(observer : any,page : any, count : any) :any {
    let url = "foundItem/admin/vip/allDelete?page="+page+"&count="+count;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  vipItemFundSetDisable(id : string, observer : any){
    let url = "foundItem/admin/vip/disable?id="+id;
    this.httpClient
    .post<any[]>(this.serverUrl.concat(url),null)
    .subscribe(observer);
  }
  vipItemFundSetDelete(id : string, observer : any){
    let url = "foundItem/admin/vip/delete?id="+id;
    this.httpClient
    .post<any[]>(this.serverUrl.concat(url),null)
    .subscribe(observer);
  }

  uploadFile(file: File, observer: any): any {
    let url = "updloadImage";
    let formData = new FormData();
    formData.append('file', file);
    this.httpClient
    .post<any[]>(this.serverImageUrlBase.concat(url), formData)
    .subscribe(observer);
  }

  saveGadget(data : any, observer : any){
    let url = "gadget/admin/add";
    this.httpClient
    .post<any[]>(this.serverUrl.concat(url),data)
    .subscribe(observer);
  }

  getAllGadget(observer : any) :any {
    let url = "gadget/admin/all";
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  getAllGadgetDisable(observer : any) :any {
    let url = "gadget/admin/allDisable";
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  getAllCommands(observer : any) :any {
    let url = "gadget/admin/command/all";
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  getLostHumains(observer : any,page : any, count : any) :any {
    let url = "lostHumain/all?page="+page+"&count="+count;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }
  getLostHumainsDisable(observer : any) :any {
    let url = "lostHumain/admin/all";
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }
  
  getUserEnable(observer : any,page : any, count : any) :any {
    let url = "root/user/getAllEnable?page="+page+"&count="+count;
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),null)
      .subscribe(observer);
  }

  getUserDisable(observer : any,page : any, count : any) :any {
    let url = "root/user/getAllDisable?page="+page+"&count="+count;
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),null)
      .subscribe(observer);
  }

  updateUser( data : any, observer : any) :any {
    let url = "root/user/activate?login="+data.username+"&role="+data.roles;
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);
  }
}
