import { Component, OnInit } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { ApiClientService } from '../api-client.service';

@Component({
  selector: 'app-gadget-create-form',
  templateUrl: './gadget-create-form.component.html',
  styleUrls: ['./gadget-create-form.component.scss']
})
export class GadgetCreateFormComponent implements OnInit {


  static fromListObserver;
  static fromList = false;
  static fromListItem;
  file = null;
  form: NgForm;
  item;
  constructor(private apiClientService: ApiClientService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {

  }


  selectFile(event) {
    this.uploadFile(event.target.files);
  }

  uploadFile(files: FileList) {
    this.file = null;
    if (files.length == 0) {
      console.log("No file selected!");
      return;
    }
    this.file = files[0];
    var output = <HTMLImageElement>document.getElementById('output');
    output.src = URL.createObjectURL(this.file);
    output.onload = function () {
      URL.revokeObjectURL(output.src)
    }
  }


  saveInfo(form: NgForm) {
    this.before();
    let item =
      {
        "label": form.value['label'],
        "price": form.value['price'],
        "constructor": form.value['cons'],
        "constructorPhone": form.value['phone'],
        "imageUrl": null,
        "detail": form.value['detail'],
        "qty": form.value['quantity']
      }
       this.item = item;
    if(GadgetCreateFormComponent.fromList){
      let item =
      {
        "label": (<HTMLInputElement>document.getElementById("label")).value,
        "price": (<HTMLInputElement>document.getElementById("price")).value,
        "constructor": (<HTMLInputElement>document.getElementById("constructor")).value,
        "constructorPhone": (<HTMLInputElement>document.getElementById("phone")).value,
        "detail": (<HTMLInputElement>document.getElementById("detail")).value,
        "imageUrl": GadgetCreateFormComponent.fromListItem.imageUrl,
        "id" : GadgetCreateFormComponent.fromListItem.id,
        "createdDate" : GadgetCreateFormComponent.fromListItem.createdDate,
        "qty": (<HTMLInputElement>document.getElementById("quantity")).value
      }
      this.item = item;
    }
   
    this.form = form;

    if (this.file != null) {
      this.apiClientService.uploadFile(this.file, this.saveImageObserver);
    } else {
      this.apiClientService.saveGadget(this.item, this.saveFoundItemObserver);
    }
  }
  saveFoundItemObserver = {
    next: (response) => {
      this.after();
      this.form.resetForm();
      if(GadgetCreateFormComponent.fromList){
        GadgetCreateFormComponent.fromListObserver.next();
      }
    },
    error: (error) => {
      console.log(error);
      this.after();
    }

  }

  saveImageObserver = {
    next: (response) => {
      this.item.imageUrl = response.url;
      if (this.file != null) {
        this.file = null;
        this.apiClientService.saveGadget(this.item, this.saveFoundItemObserver);
        var output = <HTMLImageElement>document.getElementById('output');
        output.src = null;
      }
    },
    error: (error) => {
      console.log(error);
      this.after();
    },
    complete: (resp) => {

    }
  }

  after() {
    document.getElementById("firstFound").style.display = "none";
    document.getElementById("secondFound").style.display = "block";
  }
  before() {
    document.getElementById("firstFound").style.display = "block";
    document.getElementById("secondFound").style.display = "none";
  }

}
